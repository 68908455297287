<template>
  <div class="container-fluid">
    <div
      class="row align-items-center justify-content-center"
      style="min-height: 65vh"
      v-if="loading"
    >
      <div class="col-1">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 mb-2 d-none">
        <h3 class="click btn-link text-muted" @click="back()">
          &#8592; VOLVER
        </h3>
      </div>

      <div class="col-12">
        <h2 class="text-uppercase font-weight-bold d-none">
          {{ product.name }}
        </h2>
      </div>

      <div class="col-12 col-md-5" v-if="product.image">
        <div class="text-center border product-image">
          <img
            :src="getImage(product.image)"
            class="img-fluid"
            alt="imagen"
            loading="lazy"
            style="width: 100%"
          />
        </div>
      </div>

      <div class="col py-0">
        <small class="text-muted">REF: {{ product.code }}</small>
        <h2 class="text-uppercase font-weight-bold d-none1 product-title">
          {{ product.name }}
        </h2>

        <h3>
          <div class="badge badge-danger d-block w-100 py-2 product-description">
            ${{ product.value | formatNumber }}
          </div>
        </h3>
        <div
          class="alert alert-primary text-center"
          v-if="product.promos && product.promos.length > 0 && getPromos"
        >
          <strong>{{ getPromos }}</strong>
        </div>
        <div class="w-100 py-3"></div>
        <small class="d-block text-muted text-uppercase">descripcion</small>
        <div class="my-1 bg-white1">
          <span
            class="d-block font-weight-bold px-0"
            style="font-family: sans-serif; white-space: break-spaces"
            >{{ product.description || "- - - - -" }}</span
          >
        </div>
      </div>
      <div class="col-12">
        <div class="my-4">
          <div
            class="row border-bottom py-2 px-0"
            v-for="(op, i) in options"
            :key="i"
          >
            <div class="col-12 font-weight-bold">
              <span class="text-uppercase mr-2">{{ op.label || op.name }}</span>
              <div class="w-100"></div>
              <div
                class="badge badge-danger mb-1"
                v-if="op.min > 0 || !op.multiple"
              >
                <small>OBLIGATORIO</small>
              </div>
              <div class="badge badge-info" v-else>
                <small>OPCIONAL</small>
              </div>
              <div class="w-100"></div>
              <small class="text-uppercase py-1 d-block"
                >Seleccionaste:
                <strong>{{ getSelected(op.selected) }}</strong></small
              >
              <div class="w-100"></div>
              <small class="text-muted" v-if="op.multiple"
                >Puedes elegir entre {{ op.min }} - {{ op.max }} opciones</small
              >
            </div>
            <div class="col-12" v-if="op.type == 'select'">
              <div class="row no-gutters px-0">
                <div
                  class="col-6 col-md-4 px-1 py-1"
                  v-for="(val, i) in op.options"
                  :key="val._id"
                >
                  <b-dropdown
                    v-if="val.optionGroup"
                    :id="`dropdown-${i}`"
                    :text="`${val.name} ${getSelectedText(
                      op.selected,
                      op,
                      val
                    )}`"
                    :menu-class="{
                      'text-uppercase': 1,
                    }"
                    :toggle-class="{
                      'text-uppercase h-100': 1,
                    }"
                    size="sm"
                    block
                    class="h-100 text-uppercase"
                    :variant="
                      op.parent == val._id ? 'secondary' : 'outline-secondary'
                    "
                  >
                    <b-dropdown-item
                      :key="i"
                      class="h-100"
                      :class="{
                        'text-danger': !s._id.enabled,
                      }"
                      v-for="(s, i) in val.optionGroup.options"
                      @click="setOption(op, s._id, val._id)"
                      :disabled="!s._id.enabled"
                    >
                      {{ s._id.name }}
                      <span v-if="s._id.value"
                        >- ${{ s._id.value | formatNumber }}</span
                      >
                      <div class="w-100"></div>
                      <small
                        v-if="!s._id.enabled"
                        class="text-danger text-uppercase"
                        >(No disponible)</small
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                  <button
                    v-else
                    @click="setOption(op, val)"
                    :class="{
                      'btn-secondary font-weight-bold':
                        op.selected == val || isIn(op, val),
                      'btn-outline-secondary ':
                        op.selected != val && !isIn(op, val),
                    }"
                    class="btn btn-block btn h-100 text-uppercase"
                    :disabled="
                      !val.enabled ||
                      (!isIn(op, val) &&
                        op.max == op.selected.length &&
                        op.selected.length != 0)
                    "
                  >
                    {{ val.name }}
                    <span v-if="val.value"> ${{ val.value }}</span>
                    <div class="w-100"></div>
                    <small
                      v-if="!val.enabled"
                      class="text-danger font-weight-bold text-uppercase"
                      >(No disponible)</small
                    >
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12" v-if="op.type == 'number'">
              <input
                type="number"
                class="form-control"
                :min="op.min"
                :max="op.max"
                @focus="$event.target.select()"
                v-model.number="op.selected"
                :class="{
                  'is-valid': op.min <= op.selected || op.max >= op.selected,
                  'is-invalid': op.max < op.selected || op.min > op.selected,
                }"
              />
              <span class="text-muted">
                Numero entre {{ op.min }} y {{ op.max }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="py-3"
          id="product-notes"
          v-if="info.settings && info.settings.style == 'delivery'"
        >
          <span class="font-weight-bold">Notas adicionales (opcional)</span>
          <textarea
            class="form-control"
            v-model="details"
            cols="10"
            rows="3"
            placeholder="Opcional"
          ></textarea>
        </div>
        <div class="w-100 my-2"></div>
        <div class="">
          <div class="row no-gutters align-items-end px-1">
            <div class="col col-md ml-auto">
              <small>CANTIDAD</small>
              <b-form-spinbutton
                v-model="qty"
                min="1"
                step="1"
                size="lg"
                class="w-100"
                :max="product.stock ? product.qty : 20"
              ></b-form-spinbutton>
            </div>
            <div class="w-100 py-2"></div>
            <div class="col-12 col-md" v-if="product.enabled">
              <button
                class="btn btn-block py-3 h-100 btn-primary font-weight-bold"
                @click="addToCart()"
                :disabled="product.stock && product.qty <= 0"
              >
                <span v-if="product.stock && product.qty <= 0"
                  >PRODUCTO SIN STOCK</span
                >
                <span v-else>AGREGAR</span>
              </button>
            </div>
            <div class="col-12 col-md" v-if="!product.enabled">
              <button
                class="btn btn-block py-3 h-100 btn-primary font-weight-bold"
                disabled
              >
                PRODUCTO NO DISPONIBLE
              </button>
            </div>
          </div>

          <div
            v-for="(item, i) in pagecomponents"
            class="content c0"
            :key="`item-${i}`"
          >
            <components :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import components from "../components/components";
const components = () => import("../components/components");

export default {
  components: {
    components,
  },
  props: ["id", "textColor"],
  data() {
    return {
      loading: true,
      product: {
        ref: {},
      },
      options: [],
      details: "",
      qty: 1,
      notes: "",
    };
  },
  computed: {
    pagecomponents() {
      return this.$store.state.components;
    },
    getPromos() {
      const promos = this.product.promos;
      const value = this.product.value;

      let rs = "";

      console.log({ promos });

      if (promos) {
        promos.forEach((promo) => {
          if (!promo.enabled) return;
          if (promo.to == "product")
            rs +=
              `$${value - promo.discount} sobre $${promo.value} unidades` +
              " \n";
          if (promo.to == "total")
            rs +=
              `-$${promo.discount} al total sobre $${promo.value} unidades` +
              " \n";
          if (promo.to == "option")
            rs +=
              `${promo.in == "$" ? "$" : ""}${promo.discount}${
                promo.in == "%" ? "%" : ""
              } dcto a ${promo.to}` + " \n";
          console.log({ promo });
        });
      }

      return rs;
    },
    settings() {
      return this.info && this.info.settings ? this.info.settings : {};
    },
    info() {
      return this.$store.state.info;
    },
    textClass() {
      return this.textColor || "text-white";
    },
    getExtras() {
      let values = new Set();

      this.options.forEach((o) => {
        if (o.selected == "" || o.selected == null) {
          //
        } else {
          if (o.selected && o.selected.value) {
            values.add(o.selected.value);
          }
          if (o.multiple) {
            o.selected.forEach((_o) => {
              if (_o && _o.value) {
                values.add(_o.value);
              }
            });
          }
        }
      });

      return values;
    },
    getDiscount() {
      let discount = 0;
      let qty = this.qty
      if (
        this.product.promos &&
        this.product.promos.length > 0
      ) {
        console.log("hay promos");
        let enabledPromos = this.product.promos.filter((p) => p.enabled);
        let product = this.product;
        // apply discount for each promo enabled
        enabledPromos.forEach((promo) => {
          console.log({promo})
          if (promo.when == "qty") {
            if (this.checkCondition(qty, promo.is, promo.value)) {
              // to total
              if (promo.to == "total") {
                if (promo.in == "$") {
                  let total = product.value * qty;
                  let newTotal = total - promo.discount;
                  discount = (newTotal * 100) / total;
                }
                if (promo.in == "%") {
                  discount = promo.discount;
                }
              }
              else if (promo.to == "product") {
                // if (promo.in == "%") {
                //   discount = (promo.discount * 100) / (product.value * qty);
                // }
                if (promo.in == "$") {
                  console.log({
                    qty: qty,
                    discount: promo.discount
                  })
                  discount = (qty * promo.discount);
                }
              }
            }
          }

          if (promo.when == "total") {
            //
          }
          if (promo.when == "minopcion" && [this.getExtras].length > 0) {
            if (promo.to == "option") {
              let filterExtras = [...this.getExtras].filter(
                (e) => e > promo.value
              );

              console.log({ filterExtras });

              if (filterExtras.length == 0) {
                console.log("no filter extras");
                return discount;
              } else {
                if (promo.in == "$") {
                  discount = promo.discount;
                }
                if (promo.in == "%") {
                  //discount = (this.total * promo.discount) / 100;
                }
                discount = Math.min(...filterExtras);
              }
            }
          }
        });
      }
      console.log({ discount });
      return discount;
    },
    total() {
      let t = 0;

      this.options.forEach((o) => {
        if (o.selected == "" || o.selected == null) {
          //
        } else {
          if (o.selected && o.selected.value) {
            t = t + o.selected.value;
          }
          if (o.multiple) {
            o.selected.forEach((_o) => {
              if (_o && _o.value) {
                t = t + _o.value;
              }
            });
          }
        }
      });

      return (this.product.value + t) * this.qty;
    },
  },
  methods: {
    load() {
      const pid = this.id;
      const { webName: _id } = this.$store.state.info;
      this.$http
        .get(`${_id}/product/${pid}`)
        .then((res) => {
          this.product = res.data.data;
          this.$store.commit("setProduct", res.data.data);
          console.log(res.data.data);
          document.title = `${res.data.data.name.toUpperCase()} `;
          this.options = res.data.data.newOptions.map((o) => {
            let op = {
              _id: o.ref._id,
              type: o.ref.type,
              name: o.custom_label || o.ref.name,
              options: o.ref.options.map((v) => v._id),
              selected: "",
              min: o.ref.min,
              max: o.ref.max,
              multiple: o.ref.multiple,
            };
            if (op.type == "select" && op.multiple) op.selected = [];
            else if (op.type == "number") op.selected = o.ref.min;
            else op.selected = "";

            return op;
          });
        })
        .finally(() => (this.loading = false));
    },
    getSelected(selected) {
      if (typeof selected == "number") return selected;
      if (selected == "" || selected == null) return "Nada aun";
      if (Array.isArray(selected)) {
        return selected.map((s) => s.name).join(",");
      } else if (selected.name) return selected.name;
      return selected;
    },
    // getSelectedText(selected, op) {
    getSelectedText() {
      return "";
      // let result = this.getSelected(selected)
      // if(op.selected != selected) return false
      // if(result == "Nada aun") return ''
      // else return `\n\n\n ${result}`
    },
    isIn(op, val) {
      // console.log({
      //   name: val.name,
      //   op,
      //   val
      // })
      if (
        Array.isArray(op.selected) &&
        op.selected.find((o) => o._id == val._id)
      )
        return true;

      return false;
    },
    contains() {
      // return true
    },
    setOption(op, val, parent = null) {
      if (op.multiple) {
        if (!Array.isArray(op.selected)) op.selected = [];
        if (op.selected.indexOf(val) == -1) {
          if (op.selected.length >= op.max) return false;
          op.selected.push(val);
        } else {
          op.selected.splice(op.selected.indexOf(val), 1);
        }
      } else {
        op.selected = val;
      }
      op.parent = parent;
      console.log({ op, val });
    },
    changeValue(e) {
      event.preventDefault();
      console.log(e);
    },
    addToCart() {
      let b = false;
      let t = 0;
      this.options.forEach((o) => {
        if (
          (o.selected == "" || o.selected == null) &&
          !Array.isArray(o.selected)
        ) {
          console.log("select vacio o null", o.name);
          if (o.type == "number") return false;
          b = true;
        } else {
          if (o.selected && o.selected.value) t = t + o.selected.value;
          if (o.multiple) {
            o.selected.forEach((_o) => {
              if (_o && _o.value) t = t + _o.value;
            });
          }
        }
        if (
          Array.isArray(o.selected) &&
          o.multiple &&
          o.selected.length < o.min
        ) {
          console.log("no selecciono nada");
          b = true;
        }
      });

      let exist = this.$store.state.products.filter(
        (p) => p._id == this.product._id
      );
      let existQty = exist.reduce((t, c) => t + c.qty, 0);
      if (b) {
        this.$swal({
          icon: "error",
          title: "Debe seleccionar las opciones requeridas",
        });
      } else {
        if (
          this.product.stock &&
          exist &&
          existQty + this.qty > this.product.qty
        ) {
          this.$swal({
            icon: "error",
            title: "No puedes agregar mas existencias al carro.",
          });
          return console.log("Mucha cantidad");
        }
        // if(exist) return false
        this.$store.commit("addProduct", {
          ...this.product,
          options: this.options,
          qty: this.qty,
          extra: t,
          discount: this.getDiscount,
          details: this.details,
          total: (this.product.value + t) * this.qty - this.getDiscount,
          tempid: Date.now(),
        });
        this.$gtag.event("add_to_cart", {
          event_category: "products",
          event_label: "User add product",
          value: 1,
        });
        this.$swal({
          //icon: "success",
          title: "Producto agregado",
          buttons: {
            cart: {
              text: "Ver mi pedido!",
              value: "go_cart",
            },
            ok: {
              text: "Aceptar",
              value: "ok",
            },
          },
        }).then((val) => {
          if (val == "go_cart") {
            this.$router.push(this.getLink("pedido"));
          }
        });
        this.$emit("success");
      }
    },
    back() {
      this.$router.back();
    },
    getValues(values = []) {
      return values.split(",");
    },
  },
  mounted() {
    console.log("Product mounted", this.id);
    this.load();
  },
  watch: {
    total() {
      console.log("emit");
      let discount = "";
      if (this.getDiscount)
        discount = `(-$${this.formatNumber(this.getDiscount)})`;

      this.$emit(
        "update",
        `${this.product.name} - $${this.formatNumber(this.total)} ${discount}`
      );
    },
    qty() {
      if (this.qty < 1) {
        this.$swal({
          title: "Error",
          text: "Debe ingresar una cantidad valida (1 o más)",
        });
        this.qty = 1;
      }
    },
    id() {
      this.load();
    },
  },
};
</script>